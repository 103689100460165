// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products{
  background-position: 20% 1%;
  background-size: cover;
  background-color: rgb(1, 45, 1);
    
}

.line::after{
    margin-bottom: 1.4rem;
     
  }

@media screen and (max-width:700px) {
    
    table{
        margin-top: 1.8rem;
        display:flex;
        font-size: 10px;
        margin: 0;
      }
      .locateUs p{ 
        display: inline-block;
        font-size: 1.3rem;
      }
      .line::after{
        display:flex;
        width: 100%;
        margin-bottom: 1.4rem;
        
      }
      .tcolors{
        display: none;
      }
      
      tr {
        width: 100%;
        padding: 0;
      }
      .locateUs{
        margin-top: 3rem;
      }
      .colors::after{
        margin-bottom: 4rem;
      }
      td{
        margin-top: 5rem;
        width: 100%;
        padding: 1rem ;
         border: 1px solid transparent;
         color: grey;
    }
    .tech2{
        width: 100%;
    }
    .tech2 p{
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .techie1{
        display: none;
    }
    .sketch{
      display: none;
    }
      
}
`, "",{"version":3,"sources":["webpack://./src/styles/Location.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,+BAA+B;;AAEjC;;AAEA;IACI,qBAAqB;;EAEvB;;AAEF;;IAEI;QACI,kBAAkB;QAClB,YAAY;QACZ,eAAe;QACf,SAAS;MACX;MACA;QACE,qBAAqB;QACrB,iBAAiB;MACnB;MACA;QACE,YAAY;QACZ,WAAW;QACX,qBAAqB;;MAEvB;MACA;QACE,aAAa;MACf;;MAEA;QACE,WAAW;QACX,UAAU;MACZ;MACA;QACE,gBAAgB;MAClB;MACA;QACE,mBAAmB;MACrB;MACA;QACE,gBAAgB;QAChB,WAAW;QACX,cAAc;SACb,6BAA6B;SAC7B,WAAW;IAChB;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;QACX,UAAU;QACV,SAAS;IACb;IACA;QACI,aAAa;IACjB;IACA;MACE,aAAa;IACf;;AAEJ","sourcesContent":[".products{\n  background-position: 20% 1%;\n  background-size: cover;\n  background-color: rgb(1, 45, 1);\n    \n}\n\n.line::after{\n    margin-bottom: 1.4rem;\n     \n  }\n\n@media screen and (max-width:700px) {\n    \n    table{\n        margin-top: 1.8rem;\n        display:flex;\n        font-size: 10px;\n        margin: 0;\n      }\n      .locateUs p{ \n        display: inline-block;\n        font-size: 1.3rem;\n      }\n      .line::after{\n        display:flex;\n        width: 100%;\n        margin-bottom: 1.4rem;\n        \n      }\n      .tcolors{\n        display: none;\n      }\n      \n      tr {\n        width: 100%;\n        padding: 0;\n      }\n      .locateUs{\n        margin-top: 3rem;\n      }\n      .colors::after{\n        margin-bottom: 4rem;\n      }\n      td{\n        margin-top: 5rem;\n        width: 100%;\n        padding: 1rem ;\n         border: 1px solid transparent;\n         color: grey;\n    }\n    .tech2{\n        width: 100%;\n    }\n    .tech2 p{\n        width: 100%;\n        padding: 0;\n        margin: 0;\n    }\n    .techie1{\n        display: none;\n    }\n    .sketch{\n      display: none;\n    }\n      \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
